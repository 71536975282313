.outset {
  border: 3px solid;
  border-bottom-color: #828282;
  border-right-color: #828282;
  border-top-color: #fff;
  border-left-color: #fff;
  background-color: #c3c3c3;
  padding: 6px;
}

.inset {
  border: 3px solid;
  border-top-color: #828282;
  border-left-color: #828282;
  border-right-color: #fff;
  border-bottom-color: #fff;
}
