@font-face {
  font-family: 'MS Sans Serif';
  src: url('./assets/MSSansSerif.woff2') format('woff2'), url('./assets/MSSansSerif.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'MS Sans Serif';
  src: url('./assets/MSSansSerifBold.woff2') format('woff2'), url('./assets/MSSansSerifBold.woff2') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

html,
body {
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: #333;
  width: 100%;
  height: 100%;
  user-select: none;
  font-size: 11px;
  font-family: MS Sans Serif, sans-serif;
  -webkit-font-smoothing: none;
  font-smoothing: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  box-sizing: border-box;
}
