.window {
  border: 1px solid #000;
  background-color: #c3c3c3;
}

.titleBar {
  border-bottom: 1px solid #000;
  height: 19px;
  font-weight: bold;
  color: #fff;
  line-height: 18px;
  text-align: center;
  display: grid;
  grid-template-columns: 18px auto 18px;
  grid-template-rows: 18px;
}

.title {
  background-color: #000082;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  letter-spacing: 0.5px;
}

.button {
  background-color: #c3c3c3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineIcon {
  margin-top: -1px;
  margin-left: -1px;
  background-color: #fff;
  border: 1px solid #000;
  width: 13px;
  height: 3px;
  box-shadow: 1px 1px 0 #828282;
}

.minimizeIcon {
  width: 100%;
  height: 100%;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 2px solid #818181;
  border-bottom: 2px solid #818181;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minimizeIcon:before {
  content: '';
  display: block;
  margin-top: 1px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}

.menuBar {
  height: 19px;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menuListItem,
.menuItem {
  position: relative;
  padding: 0 8px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: capitalize;
}

.menuListItem.separator {
  border-bottom: 1px solid #000;
}

.menuList {
  position: absolute;
  top: 18px;
  left: 0;
  width: 142px;
  border: 1px solid #000;
  background-color: #c3c3c3;
  color: #000;
  z-index: 1;
  box-shadow: 1px 1px 0 #828282;
}

.menuItem.active,
.menuListItem:hover {
  background-color: #000082;
  color: #fff;
}

.menuItem:first-letter,
.menuListItem:first-letter {
  display: inline;
  padding-left: 1px;
  padding-right: 1px;
  margin-right: -1px;
  border-bottom: 1px solid #000;
}

.menuItem.active:first-letter,
.menuListItem:hover:first-letter {
  border: none;
}
